import { ArticlePreviewResponse } from "authory-api-types/dist/types";
import { getThumbnaiPlaceholderIcon } from "../ContentList/ContentList.utils";
import { ContentListImageContainer, ContentListImage, CLPlaceholderWrapper } from "./ItemImage.styles";

interface ItemImageProps {
    item: ArticlePreviewResponse;
    smallImage?: boolean;
}

export const ItemImage = ({ item, smallImage = false }: ItemImageProps) => {
    return (
        <ContentListImageContainer $useBorder={!item.thumbnailImage} $smallImage={smallImage}>
            <ContentListImage src={item.thumbnailImage || undefined} />
            {!item.thumbnailImage && <CLPlaceholderWrapper>{getThumbnaiPlaceholderIcon(item.type)}</CLPlaceholderWrapper>}
        </ContentListImageContainer>
    );
};
