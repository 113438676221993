import styled from "styled-components"

export const ContentListImageContainer = styled.div<{ $useBorder?: boolean, $smallImage?: boolean }>`
    width: ${({ $smallImage }) =>  $smallImage ? "72px" : "90px"};
    height: ${({ $smallImage }) =>  $smallImage ? "47px" : "58px"};
    border-radius: 6px;
    overflow: hidden;
    background: ${({ theme }) => theme ? theme.v3.colors.grey96 : "white"};
    position: relative;
    border: ${({ $useBorder }) => $useBorder ? "2px solid white" : undefined};
    margin-right: ${({ theme }) => `${theme.v3.spacings.spacing6}px`};
`

export const ContentListImage = styled.img`
    width: 120px;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    flex-shrink: 0;
    
`

export const CLPlaceholderWrapper = styled.div`
    display: inline-block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    svg {
        width: 24px;
        height: 24px;
    }
`;